import React from 'react'
import AboutUs from '../components/AboutUs'

const About = () => {
  return (
    <div>
      <AboutUs/>
    </div>
  )
}

export default About
